import Img from 'gatsby-image';
import React from 'react';
import { GraphQlPageFrontMatter } from '../../types';
import './index.scss';

const Portfolio = (props: GraphQlPageFrontMatter) => {
  return (
    <article className="portfolio-single">
      <section className="portfolio-single-body">
        {props.attachment && (
          <div className="portfolio-single-body-image">
            <Img fluid={props.attachment.childImageSharp.fluid} />
          </div>
        )}
        <div className="portfolio-single-body-title">
          <h3>{props.title}</h3>
        </div>
      </section>
      <section className="portfolio-single-info">
        <dl className="portfolio-single-info-details">
          {props.client && (
            <div>
              <dt>Client:</dt>
              <dd>{props.client}</dd>
            </div>
          )}
          {props.categories && (
            <div>
              <dt>Categories:</dt>
              <dd>
                {props.categories.map((category: string, idx: number) => (
                  <span key={idx}>{category}</span>
                ))}
              </dd>
            </div>
          )}
          {props.skills && (
            <div>
              <dt>Skills:</dt>
              <dd>
                {props.skills.map((skill: string, idx: number) => (
                  <span key={idx}>{skill}</span>
                ))}
              </dd>
            </div>
          )}
        </dl>
        {props.url && (
          <a href={props.url} target="_blank">
            View Site
          </a>
        )}
      </section>
    </article>
  );
};

export default Portfolio;
