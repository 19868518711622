import { graphql } from 'gatsby';
import React from 'react';

import { PageProps, PageState } from '../types';
import Layout from '../components/Layout';
import Portfolio from '../components/Portfolio';

class Template extends React.Component<PageProps, PageState> {
  constructor(props: PageProps) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount(): void {
    this.setState({
      loading: false
    });
  }

  render() {
    return (
      <Layout loading={this.state.loading}>
        <Portfolio {...this.props.data.markdownRemark.frontmatter} />
      </Layout>
    );
  }
}

export default Template;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        attachment {
          childImageSharp {
            fluid(maxWidth: 1580) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        categories
        client
        skills
        title
        url
      }
    }
  }
`;
